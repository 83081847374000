export default {
  methods: {
    scrollToSection(section = 'top') {
      if (section === 'top') {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        return;
      }

      const element = document.querySelector(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    },
  },
};
