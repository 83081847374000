import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('partner', ['partner']),
  },

  mounted() {
    this.$nextTick(() => this.getPatientDetails());
  },

  methods: {
    ...mapActions('scheduling', ['setPatient', 'setAddress']),

    getPatientDetails() {
      // Temas permitidos a capturar os dados do paciente via queryString na URL
      // e popular esses valores no formulário de checkout
      const allowedThemes = ['sqh', 'sqh2', 'ixer', 'default'];

      if (allowedThemes.includes(this.partner.theme)) {
        const query = { ...this.$route.query };
        const values = [
          { query: 'name', key: 'name' },
          { query: 'email', key: 'email' },
          { query: 'email', key: 'emailConfirmation' },
          { query: 'gender', key: 'gender' },
          { query: 'cpf', key: 'cpf' },
          { query: 'cellphone', key: 'contactPhone' },
          { query: 'date_of_birth', key: 'birthdate' },
          { query: 'cep', key: 'cep' },
          { query: 'address_number', key: 'number' },
          { query: 'address_complement', key: 'complement' },
        ];
        const payload = {};

        values.forEach((q) => {
          if (Object.prototype.hasOwnProperty.call(query, q.query)) {
            payload[q.key] = this.$route.query[q.query];

            if (q.query === 'date_of_birth') {
              const [year, month, day] = this.$route.query[q.query].split('-');
              payload[q.key] = `${day}/${month}/${year}`;
            }
          }
        });

        this.setPatient(payload);
        this.setAddress(payload);
      }
    },
  },
};
