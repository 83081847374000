<template>
  <section class="lp-modality">
    <div class="lp-modality__list">
      <div
        v-for="modality in modalities"
        :key="`tab-modality-${modality.value}`"
        :class="{ 'lp-modality__tab--selected': modality.value === chosenModality }"
        class="lp-modality__tab"
        @click="chosenModality = modality.value"
      >
        {{ modality.title }}
      </div>
    </div>

    <div
      :class="[wrapperClass]"
      class="lp-modality__wrapper"
    >
      <div class="lp-modality__specialty">
        <select-list
          v-model="localChosenSpecialty"
          :options="specialties"
          :disabled="disabled"
          placeholder="Digite o nome da especialidade"
        />
      </div>

      <div class="lp-modality__call-to-action">
        <ui-button
          :disabled="disabled"
          color="primary"
          label="Quero agendar"
          @click="$emit('choose')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SelectList from '@/components/ui/SelectList.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'AppointmentModalitySpecialty',

  model: {
    prop: 'chosenSpecialty',
    event: 'select',
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    specialties: {
      type: Array,
      required: false,
      default: () => [],
    },

    modalities: {
      type: Array,
      required: true,
    },

    chosenSpecialty: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  components: {
    SelectList,
    UiButton,
  },

  data() {
    return {
      localChosenSpecialty: null,
      chosenModality: null,
    };
  },

  computed: {
    wrapperClass() {
      if (this.modalities.length === 2) {
        return this.chosenModality === this.modalities[0].value
          ? 'lp-modality__wrapper--right'
          : 'lp-modality__wrapper--left';
      }

      return 'lp-modality__wrapper--right';
    },
  },

  watch: {
    localChosenSpecialty: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit('select', value);
      },
    },

    chosenModality: {
      deep: false,
      immediate: true,
      handler(value) {
        this.$emit('change', value);
      },
    },

    chosenSpecialty: {
      deep: true,
      immediate: true,
      handler(value) {
        this.localChosenSpecialty = value;
      },
    },
  },

  created() {
    this.chosenModality = this.modalities[0].value;
  },
};
</script>
