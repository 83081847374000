<template>
  <div
    class="select-list"
    v-click-outside="() => ui.showList = false"
  >
    <!-- :style="`background-image: url('${caret}');`" -->
    <input
      :value="inputValue"
      :disabled="disabled"
      :placeholder="placeholder || 'Digite o nome da especialidade'"
      class="select-list__input"
      @focus="ui.showList = true"
      @input="inputValue = $event.target.value"
    >

    <transition
      name="fade"
      mode="in-out"
      v-if="ui.showList"
    >
      <ul
        class="select-list__list"
      >
        <li
          v-for="(option, index) in filteredList"
          :key="`select-list-option-${index}`"
          :class="{ 'select-list__option--disabled': !option.id } "
          class="select-list__option"
          @click="option.id && chooseOption(option)"
        >
          {{ option.name }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';

export default {
  name: 'SelectList',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  model: {
    prop: 'selected',
    event: 'select',
  },

  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },

    optionsLabel: {
      type: String,
      required: false,
      default: () => null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    selected: {
      type: Object,
      required: false,
      default: () => null,
    },

    placeholder: {
      type: String,
      required: false,
      default: () => null,
    },
  },

  data() {
    return {
      selectedOption: null,
      inputValue: '',
      ui: {
        showList: false,
      },
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),

    caret() {
      const url = `${
        document.location.protocol
      }//${
        document.location.hostname
      }${
        document.location.port
          ? `:${document.location.port}`
          : ''
      }`;

      return `${url}/static/img/ui/${this.partner.theme}-caret-select.svg`;
    },

    filteredList() {
      const notFound = {
        id: null,
        name: 'Especialidade não encontrada.',
      };

      if (this.options.length > 0) {
        const filtered = this.options.filter((option) => {
          const search = this.removeDiacritics(this.inputValue.toLowerCase()).trim();

          return this.removeDiacritics(option.name.toLowerCase())
            .indexOf(search) !== -1;
        });

        return filtered.length > 0 ? filtered : [notFound];
      }

      return [notFound];
    },
  },

  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selectedOption = value;
        this.inputValue = this.selectedOption ? this.selectedOption.name : '';
      },
    },

    selectedOption: {
      deep: true,
      immediate: false,
      handler(value) {
        this.$emit('select', value);
      },
    },

    inputValue: {
      deep: true,
      immediate: false,
      handler(value) {
        if (!value) {
          this.selectedOption = null;
        }
      },
    },
  },

  methods: {
    chooseOption(option = null) {
      if (option) {
        this.selectedOption = option;
        this.inputValue = option.name;
        this.ui.showList = false;
      }
    },

    removeDiacritics(string) {
      const char = {
        'a': 'á|à|ã|â|À|Á|Ã|Â',
        'e': 'é|è|ê|É|È|Ê',
        'i': 'í|ì|î|Í|Ì|Î',
        'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c': 'ç|Ç',
        'n': 'ñ|Ñ',
      };

      for (const pattern in char) {
        string = string.replace(new RegExp(char[pattern], 'g'), pattern);
      }

      return string;
    },
  },
};
</script>
